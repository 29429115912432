.compactCard{
    display: flex;
    flex: 1;
    height: 7rem !important;
    border-radius: 0.7rem;
    color: white;
    position: relative;
    cursor: pointer;
    padding: 1rem;
    /* transition: 0.3s; */
}
.compactCard:hover{
    box-shadow: none !important;
}
.radialbar{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 1rem;
}
.CircularProgressbar{
    width: 4rem !important;
    overflow: visible;
}
.CircularProgressbar-path{
    /* تحدد لون الخط (الحد) للعنصر بأن يكون أبيض. */
    stroke: white !important;
    /* تحدد سمك الخط (الحد) للعنصر بـ 12 بكسل */
    stroke-width: 12px !important;
    filter: drop-shadow(2px 4px 6px white);
}
.CircularProgressbar-trail{
    display: none;
}
.CircularProgressbar-text{
    fill: white !important;
}
.radialbar>span{
    font-size: 17px;
    font-weight: bold;
}
.detail{
flex: 1;
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: flex-end;
}
.detail>span:nth-child(2){
    font-size: 22px;
    font-weight: bold;
}
.detail>span:nth-child(3){
    font-size: 12px;
    
}
/* ExpandadCard */
.Expandedcard{
    position: absolute;
    width: 60%;
    height: 70vh;
    z-index: 9;
    left: 13rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.Expandedcard>span:nth-of-type(1){
    color: white;font-size: 26px;
    font-weight: bold;
    text-shadow: 0px 0px 15px white;
}
.Expandedcard>span:nth-of-type(2){
    color: white;
    font-style: 16px;
}
.chartContainer{
    width: 70%;
}
@media screen and (max-width:1200px){
    .Expandedcard{
        top: 4rem;
        height: 70vh;
        left: 6rem;
    }
}
@media screen and (max-width:768px){
    .Expandedcard{
        top: 8rem;
        height: 50%;
        left: 45px;
        width: 80%;
    }
}