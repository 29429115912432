.mainDash{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
@media screen and (max-width:1200px){
    .mainDash{
        justify-content: flex-start;
        margin-top: 2rem;
    }
}
@media screen and (max-width:768px){
    .mainDash{
        align-items: center;
    }
}