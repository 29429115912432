.sidbar{
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 4rem;
    transition: all 300ms ease;
}
.logo{
    display: flex;
    height: 5rem;
    font-weight: bold;
    font-size: 22px;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    height: 4%;
}
.logo >img{
    width: 3rem;
    height: 3rem;
}
.logo>span>span{
    color: var(--pink);
}
/* menu */
.menu{
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.menuItem{
    display: flex;
    align-items: center;
    gap: 1rem;
    height: 2rem;
    margin-left: 2rem;
    position: relative;
    transition: all 300ms ease;
    font-size: 14px;
}
.menuItem:hover{
    cursor: pointer;

}
.active{
   background: var(--activeItem); 
   margin-left: 0;
   border-radius: 0.7rem;
}
.active::before{
    content: '';
    width: 8px;
    height: 100%;
    background: var(--pink);
    margin-right: calc(1rem - 8px);
}
.menu .menuItem:last-child{
position: absolute;
bottom: 2.3rem;
width: 100%;
}
@media screen and (max-width:1200px){
    .menuItem>span{
        display: none;
    }
    .logo{
        display: none;
    }
}
@media screen and (max-width:768px){
.sidbar{
    position: fixed;
    z-index: 9;
    background: #ffe0e0;
    width: 50%;
    padding-right: 1rem;
    height: 100%;
}
.menuItem>span{
    display: block;
}
.logo{
    display: flex;
}
.menu .menuItem:last-child{
    position: relative;
    margin-top: 2rem;
}
.Bars{
    display: flex;
    position: fixed;
    left: 2px;
    top: 2rem;
    z-index: 9;
    background: #ffe0e0;
    padding: 10px;
    border-radius: 2px;
}
}