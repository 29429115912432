.RightSide{
    display: flex;
    flex-direction: column;
    width: 95%;
    justify-content: space-evenly;
}
.RightSide img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
}
.Updates{
    width: 85%;
    background: white;
    border-radius: 0.7rem;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    gap: 1rem;
    padding: 1rem;
}

.update{
display: flex;
gap: 0.5rem;
}
.noti>div>span:nth-of-type(1){
    font-weight: bold;
}
@media screen and (max-width:768px){
    .update{
        display: flex;
        align-items: flex-end;
    }
}
